import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";

// component
import Link from "../utils/link";
import Section from "../utils/section";
import { useFirebase } from "../utils/useFirebase";

const validationSchema = yup.object({
  name: yup.string().required(),
  piva: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
  privacy: yup.number().oneOf([1]),
});

interface ContactFormProps {
  language?: "it" | "en" | "es";
}

const ContactForm = ({ language }: ContactFormProps) => {
  const [isSent, setIsSent] = React.useState(false);
  const { firestore } = useFirebase();

  const translations = {
    it: {
      title: "Di che cosa hai bisogno?",
      description:
        "Compila il form senza alcun impegno e verrai contattato al più presto.",
      labels: {
        name: "Nome",
        phone: "Telefono",
        email: "Email",
        piva: "Partita iva",
        message: "Messaggio",
        privacy: "Ho letto e accetto la",
        marketing:
          "Do il consenso al trattamento dei miei dati per fini commerciali",
        submit: "contattaci",
        successTitle: "Grazie per averci scritto.",
        successMessage: "Sarà contattato a breve da un nostro operatore.",
      },
      messages: {
        subject: "Richiesta informazioni da {name} - Mettifogo S.r.l",
        text: `Gentile {name}, grazie per averci contattati. Saremo felici di prendere in carico la Sua richiesta e risponderLe nel più breve tempo possibile. Per eventuali comunicazioni potrà essere contattato via telefono al numero {phone} o al suo indirizzo email {email}. Il suo messaggio: {message}. Partita iva: {piva}. Team Mettifogo S.r.l`,
        html: `
            <p>Gentile {name},<br/>grazie per averci contattati.</p>
            <p>Saremo felici di prendere in carico la Sua richiesta e risponderLe nel più breve tempo possibile.</p>
            <p>Per eventuali comunicazioni potrà essere contattato via telefono al numero <strong>{phone}</strong> o al suo indirizzo email <strong>{email}</strong>.</p>
            <p>Il suo messaggio: <br/>{message}</p>
            <p>Partita iva: <br/>{piva}</p>
            <p><i>Team Mettifogo S.r.l</i></p>
          `,
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
    },
    en: {
      title: "What do you need?",
      description:
        "Fill out the form without any obligation and you will be contacted as soon as possible.",
      labels: {
        name: "Name",
        phone: "Telephone",
        email: "E-mail",
        piva: "VAT number",
        message: "Message",
        privacy: "I have read and accept the",
        marketing:
          "I consent to the processing of my data for commercial purposes",
        submit: "Contact us",
        successTitle: "Thank you for contacting us.",
        successMessage:
          "You will be contacted shortly by one of our operators.",
      },
      messages: {
        subject: "Information request from {name} - Mettifogo S.r.l",
        text: `Dear {name}, thank you for contacting us. We will be happy to process your request and respond as soon as possible. You can be contacted via phone at {phone} or at your email address {email}. Your message: {message}. VAT number: {piva}. Team Mettifogo S.r.l`,
        html: `
            <p>Dear {name},<br/>thank you for contacting us.</p>
            <p>We will be happy to process your request and respond as soon as possible.</p>
            <p>You can be contacted via phone at <strong>{phone}</strong> or at your email address <strong>{email}</strong>.</p>
            <p>Your message: <br/>{message}</p>
            <p>VAT number: <br/>{piva}</p>
            <p><i>Team Mettifogo S.r.l</i></p>
          `,
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
    },
    es: {
      title: "¿Necesitas más informaciones?",
      description:
        "Rellena el formulario sin ningún compromiso y te contactaremos lo antes posible.",
      labels: {
        name: "Nombre",
        phone: "Teléfono",
        email: "Correo electrónico",
        piva: "Número de IVA",
        message: "Mensaje",
        privacy: "He leído y acepto la",
        marketing:
          "Doy mi consentimiento para el tratamiento de mis datos con fines comerciales",
        submit: "Contáctenos",
        successTitle: "Gracias por escribirnos.",
        successMessage:
          "Será contactado pronto por uno de nuestros operadores.",
      },
      messages: {
        subject: "Solicitud de información de {name} - Mettifogo S.r.l",
        text: `Estimado {name}, gracias por contactarnos. Estaremos encantados de gestionar su solicitud y responderle lo antes posible. Puede ser contactado por teléfono al número {phone} o en su correo electrónico {email}. Su mensaje: {message}. Número de IVA: {piva}. Equipo de Mettifogo S.r.l`,
        html: `
            <p>Estimado {name},<br/>gracias por contactarnos.</p>
            <p>Estaremos encantados de gestionar su solicitud y responderle lo antes posible.</p>
            <p>Puede ser contactado por teléfono al número <strong>{phone}</strong> o en su correo electrónico <strong>{email}</strong>.</p>
            <p>Su mensaje: <br/>{message}</p>
            <p>Número de IVA: <br/>{piva}</p>
            <p><i>Equipo de Mettifogo S.r.l</i></p>
          `,
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
    },
  };

  const t = translations[language || "it"];

  const formik = useFormik({
    initialValues: {
      name: "",
      piva: "",
      phone: "",
      email: "",
      message: "",
      privacy: 0,
      marketing: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (firestore) {
        const db = firestore.getFirestore();
        firestore
          .addDoc(firestore.collection(db, "contacts"), {
            to: [values.email],
            bcc: ["info@mettifogo.eu"],
            message: {
              subject: t.messages.subject.replace("{name}", values.name),
              text: t.messages.text
                .replace("{name}", values.name)
                .replace("{phone}", values.phone)
                .replace("{email}", values.email)
                .replace("{message}", values.message)
                .replace("{piva}", values.piva),
              html: t.messages.html
                .replace("{name}", values.name)
                .replace("{phone}", values.phone)
                .replace("{email}", values.email)
                .replace("{message}", values.message)
                .replace("{piva}", values.piva),
            },
          })
          .then(() => {
            setIsSent(true);
            resetForm();
          });
      }
    },
  });

  return (
    <Box id="contact-form" pt={3}>
      <Section>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Stack spacing={1} textAlign="center">
              <Typography variant="h3" component="h2">
                {t.title}
              </Typography>
              <Typography>{t.description}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label={t.labels.name}
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                  />
                  <TextField
                    fullWidth
                    label={t.labels.phone}
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                  <TextField
                    fullWidth
                    label={t.labels.email}
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <TextField
                    fullWidth
                    label={t.labels.piva}
                    id="piva"
                    name="piva"
                    value={formik.values.piva}
                    onChange={formik.handleChange}
                    error={formik.touched.piva && Boolean(formik.errors.piva)}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label={t.labels.message}
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        id="privacy"
                        name="privacy"
                        value={1}
                        onChange={formik.handleChange}
                      />
                    }
                    label={
                      <>
                        {t.labels.privacy}{" "}
                        <Link
                          title="Privacy policy"
                          type="external"
                          href={t.privacyLink}
                          underline="always"
                        >
                          privacy policy
                        </Link>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="marketing"
                        name="marketing"
                        value={1}
                        onChange={formik.handleChange}
                      />
                    }
                    label={t.labels.marketing}
                  />
                  <Box justifyContent="flex-end" display="flex">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                    >
                      {t.labels.submit}
                    </Button>
                  </Box>
                </Stack>
              </form>

              {isSent && (
                <Box mt={4}>
                  <Alert severity="success">
                    <AlertTitle>{t.labels.successTitle}</AlertTitle>
                    {t.labels.successMessage}
                  </Alert>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Section>
    </Box>
  );
};

export default ContactForm;
